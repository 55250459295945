<template>
  <div class="wrap">
    <div class="content-wrap">
      <div class="head-wrap" @click="toBuyVip">
        <img class="img"
              :src="vipInfo.avatar"
              v-if="vipInfo.avatar"
              />
        <img class="img"
              src="@/assets/personalCenter/头像.png"
              v-else/>
        <!-- <img class="img-vip-txt" src="@/assets/personalCenter/icon_vip_own_status.png"/> -->
        <img class="img-vip-txt" :src="isFree ? require('@/assets/personalCenter/icon_vip_free_status.png') : require('@/assets/personalCenter/icon_vip_own_status.png')"/>
      </div>
      <div :class="[isFree?'vip-info-wrap-white':'vip-info-wrap-gradient']">
      <!-- <div class="vip-info-wrap-white"> -->
        <div class="vip-top" v-if="!isFree">
          <img class="img-hat" src="@/assets/personalCenter/icon_vip_hat.svg" />
          <span class="vip-top-title">平台版</span>
          <span class="vip-top-date" v-if="vipInfo.vip_end_time">{{ vipInfo.vip_end_time }}到期</span>
        </div>
        <div class="vip-info">
          <div class="vip-info-item" v-for="(item, index) in vipItems" :key="index">
            <div class="vip-info-num">{{ item.num }}</div>
            <div class="vip-info-num-desc">{{ item.desc }}</div>
            <div class="vip-info-btn" @click="doFn(item.btnFn)">{{ item.btnTxt }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '../../../router'
import { getVipInfo } from '@/api/vip.js'


export default {
  data(){
    return {
      // isFree: false,
      vipInfo:null,
      vipItems:[
        {
          id: 0,
          num:0,
          desc:'免费次数',
          btnTxt:'立即充值',
          btnFn:'toBuyVip'
        },
        {
          id: 1,
          num:0,
          desc:'分享积分',
          btnTxt:'分享',
          btnFn:'toShare'
        }
      ]
    }
  },
  computed: {
    isFree(){
      let isFree = true
      let vipInfo = this.vipInfo
      if(vipInfo && vipInfo.vip_end_time != null && vipInfo.vip_end_time.length > 0){
        let curDate = new Date()
        let vipExpired = new Date(vipInfo.vip_end_time)
        isFree = curDate > vipExpired
      }
      return isFree
    }
  },
  methods: {
    loadVipInfo(){
      let that = this
      getVipInfo().then(res=>{
        let data = res.data
        let freeVipInfo = that.vipItems[0]
        let shareVipInfo = that.vipItems[1]
        freeVipInfo.num = data.report_num
        shareVipInfo.num = data.integral
        that.vipInfo = data
        let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
        if(data.vip_end_time == "0000-00-00 00:00:00"){
           userInfo.vip_end_time = ""
        }else{
          userInfo.vip_end_time = data.vip_end_time
        }
        window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
      })
    },
    doFn(fn){
      let f = this.$options.methods
      f[fn]()
    },
    toBuyVip(){
      router.push('/buyVip')
    },
    toShare(){
      router.push('/vipShare')
    }
  },
  created () {
    this.loadVipInfo()
  }
}
</script>

<style lang="scss" scoped>
.wrap{
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:100rem;
  margin-top: -100rem;
  .content-wrap{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    align-self: stretch;
    .head-wrap{
      width: 160rem;
      height: 160rem;
      display: flex;
      justify-content: center;
      position: relative;
      .img{
        width: 160rem;
        height: 160rem;
        border-radius: 160px;
        background: url(<path-to-image>), lightgray -14.574px -2.397px / 112.5% 168.75% no-repeat;
        border-radius: var(--radius-radius_max, 100px);
        border: 4px solid #FDECC9;
      }
      .img-vip-txt{
        // height: 48px;
        // width: 128px;
        position: absolute;
        bottom: -24rem;
      }
    }
    .vip-info-wrap-white,
    .vip-info-wrap-gradient{
      width:100%;
      display: flex;
      flex-direction: column;
      border-radius: 24px;
      background:  #FFF;
      .vip-top{
        display: flex;
        padding: 24px 32px;
        align-items: center;
        align-self: stretch;
        .img-hat{
          width: 32px;
          height: 32px;
        }
        .vip-top-title{
          flex: 1;
          color: #3C2003;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 36px; /* 150% */
          margin-left: 8px;
          margin-right: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        .vip-top-date{
          color: #999;
          text-align: right;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 150% */
        }
      }
      .vip-info{
        display: flex;
        .vip-info-item{
          flex:1;
          display: flex;
          padding: 32px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // box-sizing: border-box;
          .vip-info-num{
            color:  #333;
            text-align: center;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 54px; /* 150% */
          }
          .vip-info-num-desc{
            color: #999;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px; /* 150% */
            margin-top: 8px;
          }
          .vip-info-btn{
            display: flex;
            padding: 8px 28px;
            justify-content: center;
            align-items: center;
            gap: 6px;
            border-radius: 8px;
            border: 1px solid #2196F3;
            color: var(--Brand-Color_6default, #2196F3);
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px; /* 150% */
            margin-top: 40px;
            &:hover{
              cursor: pointer;
            }
          }

        }
      }
    }
    .vip-info-wrap-gradient{
      background: linear-gradient(180deg, rgba(253, 236, 201, 0.60) 0%, rgba(233, 195, 131, 0.00) 37.09%), #FFF;
    }
  }
}
.font-24-400-3C2003,
.font-24-400-333,
.font-24-999-400,
.font-24-400-2196F3{
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;

}


</style>